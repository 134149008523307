<template>
  <div class="login">
    <div class="loginitem">
      <el-image
        @click="changelogin(false)"
        class="itemimg"
        v-if="loginmethod"
        style="width: 70px; height: 70px;"
        :src="require('@/util/images/index/icon_ewm.png')"
        fit="contain"
      ></el-image>
      <el-image
        @click="changelogin(true)"
        class="itemimg"
        v-else
        style="width: 70px; height: 70px;"
        :src="require('@/util/images/index/icon_dn.png')"
        fit="contain"
      ></el-image>
      <el-form
        v-if="loginmethod"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="top"
        label-width="100px"
        class="demo-ruleForm animate__animated animate__fadeIn"
      >
        <h2>欢迎回来</h2>
        <h3>在此处登录您的帐户</h3>
        <el-form-item prop="account">
          <el-input
            v-model="ruleForm.account"
            placeholder="账号"
            @keyup.enter.native="submitForm('ruleForm')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            show-password
            placeholder="密码"
            @keyup.enter.native="submitForm('ruleForm')"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn">
          <el-button
            class="loginbtn"
            type="primary"
            v-loading.fullscreen.lock="loading"
            @click="submitForm('ruleForm')"
            >登录</el-button
          >
          <span class="forget" @click="forget">忘记密码？</span>
          <el-link class="register" @click="register" type="primary"
            >立即注册</el-link
          >
        </el-form-item>
        <div class="tipurl">
          微信登录已被删除，单击此处可收到设置密码的链接。如果您没有收到带有重置密码链接的电子邮件，请检查垃圾邮件文件夹。
        </div>
      </el-form>
      <div class="erweim animate__animated animate__fadeIn" v-else>
        <!-- <h2>扫码登录</h2> -->
        <!-- <h3>请使用微信扫描二维码登录</h3> -->
        <div id="wxbox" style="width: 240px; height: 240px;" class="itemimg1">
          <wxlogin
            appid="wx4f0027649e8b45b8"
            :scope="'snsapi_login'"
            :theme="'black'"
            :redirect_uri="
              encodeURIComponent(
                'http://infortree.com/Html/index.html#/edit'
              )
            "
            rel="external nofollow"
          >
          </wxlogin>
        </div>
        <!-- <h4>扫一扫快速登录</h4> -->
      </div>
    </div>
  </div>
</template>

<script>
import wxlogin from "vue-wxlogin";
import { Login } from "../../api/userapi";
export default {
  name: "login",

  components: { wxlogin },

  data() {
    return {
      loading: false,
      loginmethod: "true",
      ruleForm: {
        account: "",
        password: ""
      },
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        account: [
          { required: true, message: "请输入账号密码", trigger: "blur" }
        ]
      },
      appid: "wx4f0027649e8b45b8",
      scope: "snsapi_login",
      code: ""
    };
  },
  mounted() {
  },
  methods: {
    // 切换登入方式
    changelogin(e) {
      this.loginmethod = e;
    },
    // 前往忘记密码页面
    forget() {
      this.$router.push("/forget");
    },
    // 前往注册页面
    register() {
      this.$router.push("/register");
    },
    // 提交登入信息
    submitForm(formName) {
      const that = this;
      that.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          Login(that.ruleForm)
            .then(res => {
              if (res.code === 1) {
                const info = JSON.stringify(res.data.userinfo);
                window.localStorage.setItem("infortree-userinfo", info);
                window.localStorage.setItem(
                  "infortree-userid",
                  res.data.userinfo.user_id
                );
                setTimeout(res1 => {
                  that.$message.success(res.msg);
                  that.$router.push("/edit");
                  that.loading = false;
                }, 1000);
              } else {
                setTimeout(res1 => {
                  that.$message.error(res.msg);
                  that.loading = false;
                }, 1000);
              }
            })
            .catch(res => {
              that.$message.error(res);
            });
          // that.$router.push("/edit");
        } else {
          that.$message.error("提交错误请重新提交");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.erweim {
  text-align: center;
  h3 {
    margin-bottom: 50px;
  }
  h4 {
    font-size: 14px;
    color: #666666;
    margin-top: 20px;
  }
}
.itemimg {
  position: absolute;
  top: 0;
  right: 0;
}
.loginbtn {
  width: 92px;
  height: 40px;
  background: #0383e4;
  border-radius: 5px;
}
.forget {
  font-size: 14px;
  margin: 0 18px;
  color: #333333;
  line-height: 19px;
}
.btn {
  padding: 20px 0 0 0;
}
/deep/.el-form-item {
  margin-bottom: 15px;
}
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
.tipurl {
  font-size: 12px;
  margin-top: 30px;
  color: #000000;
  line-height: 18px;
}
.register {
  float: right;
}
h2 {
  font-size: 24px;
  color: #333333;
  margin-bottom: 15px;
}
h3 {
  font-size: 18px;
  color: #333333;
  margin-bottom: 70px;
}
.loginitem {
  width: 500px;
  height: 490px;
  margin: 50px auto;
  background: #ffffff;
  position: relative;
  padding: 50px 80px;
  box-shadow: 0px 5px 20px 0px rgba(8, 21, 66, 0.06);
}
</style>
